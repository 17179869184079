import * as React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { maybePluralise } from '../helpers/helpers';
import { decode } from 'he';

import Layout from '../components/Layout';
import { ArticleGrid } from '../components/ArticleGrid';

import { Container, Grid, GridItem, Icon } from '@ao-internal/components-react';

export interface TagTemplateProps {
	pageContext: {
		posts: {
			node: WPCard;
		}[];
		totalCount: number;
		totalPages: number;
		currentPage: number;
		currentTag: string;
	};
}

export class TagTemplate extends React.PureComponent<TagTemplateProps, {}> {
	render(): React.ReactNode {
		const {
			currentPage,
			totalPages,
			currentTag,
			totalCount,
		} = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === totalPages;
		const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
		const nextPage = (currentPage + 1).toString();

		return (
			<Layout>
				<Helmet title={"Articles tagged with '" + decode(currentTag) + "'"} />

				<div className="blog-tag">
					<Container className="px-0 mt-6 sub-header">
						<Grid className="items-center">
							<GridItem key="1" span={{ def: 12, md: 6 }}>
								<h1 className="text-display md:text-display-lg">
									Articles tagged with '{currentTag}'
								</h1>
							</GridItem>
							<GridItem
								key="2"
								span={{ def: 12, md: 6 }}
								className="text-right">
								<span>{maybePluralise(totalCount, 'Article', 'Articles')}</span>
							</GridItem>
						</Grid>
					</Container>

					<ArticleGrid
						articles={this.props.pageContext.posts.map((post) => post.node)}
					/>

					{totalPages > 1 && (
						<div className="text-center m-6 blog-archive__pagination">
							<div className="c-pagination">
								{!isFirst && (
									<Link
										to={'/tag/' + prevPage}
										rel="prev"
										className="c-pagination-prev">
										<Icon icon="chevron-left" label="Previous page" />
									</Link>
								)}

								{Array.from({ length: totalPages }, (_, i) => (
									<Link
										key={`pagination-number${i + 1}`}
										to={`/tag/${currentTag}/${
											i === 0 ? '' : `/tag/${currentTag}/` + i + 1
										}`}
										className={
											currentPage === i + 1
												? 'c-pagination-link c-pagination-link--active'
												: 'c-pagination-link'
										}>
										{i + 1}
									</Link>
								))}

								{!isLast && (
									<Link
										to={'/tag/' + nextPage}
										rel="next"
										className="c-pagination-next">
										<Icon icon="chevron-right" label="Next page" />
									</Link>
								)}
							</div>
						</div>
					)}
				</div>
			</Layout>
		);
	}
}

export default TagTemplate;
